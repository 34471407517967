@use '@/assets/css-vars/breakpoints' as breakpoints;


.section {
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.sectionTitle {
    text-align: center;
    font-family: lora;
    font-size: 32px;
    line-height: normal;

    @media (max-width: 768px) {
        text-align: center;
        font: lora;
        font-size: 24px;
        margin-left: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

.title {
    text-align: center;
    font-family: lora;
    font-size: 32px;
    margin : 20px 10px 5px 10px;
    line-height: normal;
}

.subTitle {
    text-align: center;
    font-family: lora;
    font-size: 24px;
    margin : 10px 5px 3px 5px;
    text-transform: none;
    line-height: normal;
}

.imageContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px;
    border-radius: 5px;
    padding: 0px !important;

    @media (max-width: 768px) {
        padding-top: 0px;
        margin-top: 0px;
        //margin-bottom: 30px;
    }
}

.sectionDescription, .description, .para, .text {
    font-family: Nunito;
    font-size: 16px;
    margin:10px 2px;
    line-height: normal;

    &.bold {
        font-weight: bold;
    }

    &.center {
        text-align: center;
    }

    &SmallText {
        font-size: 14px;
    }

    &LargeText {
        font-size: 20px;
    }

    &CenterText {
        text-align: center;
    }

    &BoldText {
        font-weight: bold;
    }
}

.gridContainer {
    @media (max-width: 768px) {
        margin: 0px !important;
        width: 100%;
    }
}

.gridItem {
    justify-content: center;
    margin-top: 5px;
    @media (max-width: 768px) {
        padding : 0px !important;
        //border: 1px solid lightgray;
    }
}

.contentOnImage {
    &Container {
        width: 100%;
        background: white;
        overflow: hidden;
        margin: 0;
        padding: 0;
        color: white;
        //font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        //border: 1px solid red;
    }

    &Content {
        max-width: 70%;
        max-height: 50%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        // want to make it transparent
        background: rgba(237, 235, 235, 0.5) !important;
        box-sizing: border-box;
        z-index: 1;
        //border: 1px solid blue;
    }
}

.panel {

    &Container {
        padding: 20px;
        //border: 1px solid red;
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            &.swapOrder {
                flex-direction: row-reverse;
            }
        }
        @media (max-width: 768px) {
            //border: 1px solid lightgray;
        }
    }

    &Image {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 300px;
        border-radius: 5px;
    }

    &Text {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 768px) {
            padding-top: 0px;
            margin-top: 0px;
            margin-bottom: 30px;
        }
    }

    &Title {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    &Description {
        font-size: 16px;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        &Title {
            text-align: center;
        }

        &Button {
            text-align: center;
        }

    }

}

.sideImagePanel {
    &Section {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    &Container {
        margin: 10px;
        //border: 1px solid red;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @media (min-width: 768px) {
            &.swapOrder {
                flex-direction: row-reverse;
            }
        }
        @media (max-width: 768px) {
            margin: 0px;
            //border: 1px solid lightgray;
        }
    }

    &ImageGridItem {
        padding: 20px !important;
        @media (max-width: 768px) {
            padding: 0px !important;
        }
    }   

    &Image {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 300px;
        border-radius: 5px;
        padding: 10px !important;
    }

    &Text {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;

        @media (max-width: 768px) {
            padding-top: 0px;
            margin-top: 0px;
            margin-bottom: 30px;
        }
    }

    &Title {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    &Description {
        font-size: 16px;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        &Title {
            text-align: center;
        }

        &Button {
            text-align: center;
        }

    }

}

.panel {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;

    @media (max-width: 768px) {
        //border: 1px solid lightgray;
    }
}
.gridPanel {
    @media (max-width: 768px) {
        border: 1px solid lightgray;
    }
    &Section {
        padding-top: 30px;
        font-size: 24px;
        text-align: justify;
    }

    &GridItem {
        padding: 10px;
    }

    &Image {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 300px;
        border-radius: 5px;
    }

    &Text {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &LargeText {
        font-size: 20px;
    }

    &CenterText {
        text-align: center;
    }

    &BoldText {
        font-weight: bold;
    }

    &Title {
        font-size: 24px;
        text-align: center;
    }

    &Description {
        font-size: 16px;
        margin-bottom: 20px;
    }
}

.imgButtonCircular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0px 10px;
    border-radius: 10px;
    cursor: pointer;

    &__outerCircle {
        padding: 5px;
        border-radius: 50%;
        border: 1px solid black;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &__innerCircle {
        position: relative;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__circle {
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }

    @media (max-width: 768px) {
        padding: 2px;
        margin: 0px;
    }
}

.imgButtons {
    &Container {
        display: flex;
        flex-wrap: wrap;
        margin-right: 15px;
        margin-left: 15px;
        align-content: center;
        justify-content: center;

        @media (max-width: 768px) {
            margin-right: 5px;
            margin-left: 5px;
        }
    }


}

.imageButtonsWithPanel {
    &Section {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &Title {
        font-size: 24px;
    }

    &description {
        font-size: 16px;
        margin-bottom: 20px;
    }

    &Panels {
        margin-top: 20px;
    }
}

.accordionSection {
    padding: 0 48px;

    @media (max-width: 768px) {
        padding: 0 10px;

    }
}

.custText {
    font-family: lora;
    font-size: 16px;
    line-height: normal;
    margin: 0px;
    display: flex;
    justify-content: left;
}

.slider {
    &Container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid red;
        width: 100%;
    }

    &Header, &Footer {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &Slider {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: bold;
        overflow: hidden;
        border: 1px solid blue;
    }

    &Item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        section {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media screen and (max-width: 768px) {
        &Container {
            //height: 300px;
        }
    }

    &Description {
        font-size: 16px;
        margin-bottom: 20px;
    }
}
// this is for the above carousel
.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: teal;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.react-multiple-carousel__arrow--right {
        right: 20px;
    }

    &.react-multiple-carousel__arrow--left {
        left: 20px;
    }

    svg {
        width: 1.5em;
        height: 1.5em;
        color: white;
    }
}
.designer-carousel-dot-list {
    bottom: 20px !important;
}